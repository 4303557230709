import React from 'react';
import { useCallback } from 'react';
import { SecondaryDescription, SecondaryTitle } from '../Typography';
import DealItem from './DealItem';

function DealRow({ title, description, dealsData, selectedIndex, setSelectedDeal }) {
  const generateDealsItems = useCallback(() => {
    const components = [];

    dealsData.forEach((item, index) => {
      components.push(
        <DealItem
          key={index}
          item={item}
          isSelected={selectedIndex?.dealIndex === index}
          setSelectedDeal={() => setSelectedDeal(index)}
        />
      );
    });

    return components;
  }, [selectedIndex, dealsData]);

  return (
    <div className="deals__row-container">
      <div className="deals__row-text">
        <SecondaryTitle>{title}</SecondaryTitle>
        <SecondaryDescription>{description}</SecondaryDescription>
      </div>
      <div className="deals__row-deals">
        {dealsData.length ? (
          <>{generateDealsItems()}</>
        ) : (
          <p className="deals__no-deals">No deals found</p>
        )}
      </div>
    </div>
  );
}

export default DealRow;
