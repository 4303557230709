import { navigate } from 'gatsby';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useCallback } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { SPRIVE_API_BASE_URL, SPRIVE_API_KEY } from '../../constants';
import DotsLoader from '../Loader';
import { SecondaryTitle } from '../Typography';
import DealRow from './DealRow';

function Deals({ editModal, setMortgageDetails, leadToHighlights, templatePage, isLenderSpecific }) {
  const [deals, setDeals] = useState(null);
  const [productId, setProductId] = useState(null)
  const [dealsError, setDealsError] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState({
    dealIndex: null,
    rowIndex: null,
  });

  const handleDealClick = useCallback(
    (tabIndex, rowIndex, dealIndex) => {
      setSelectedIndex({ dealIndex, rowIndex });

      const dealHighlightData = {
        dealIndex: dealIndex,
        rowIndex: rowIndex,
        tabData: deals[tabIndex],
        productId,
      };

      localStorage.setItem('dealData', JSON.stringify(dealHighlightData));
      
      if(!leadToHighlights) return

      setTimeout(() => {
        navigate('/deal-highlights', {
            state: {
                customLinks: [
                    {
                        title: 'Home',
                        leadsTo: 'https://sprive.com',
                        external: true
                      },
                      {
                        title: 'Remortgage',
                        leadsTo: 'https://sprive.com/mortgages/remortgage',
                        external: true
                      },
                ]
            }
        });
      }, 100);
    },
    [deals, productId]
  );

  useEffect(() => {
    if(templatePage === null) return
    setDealsError(false);
    const uuid = localStorage.getItem('uuid');

    if (!uuid) {
      navigate('/');
    }

    const fetchUrl = templatePage ? "product-sub-domain-deals" : "product-deals"

    fetch(SPRIVE_API_BASE_URL + `${fetchUrl}?uuid=${uuid}`, {
      headers: {
        api_key: SPRIVE_API_KEY,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        if (data?.success) {
          setDeals(data.data.tab_data);
          setProductId(data.data.product_recommended_id)
          setMortgageDetails({
            borrowing_amount: data.data?.borrowing_amount,
            borrowing_period: data.data?.borrowing_period
          })
        } else {
          setDealsError(true);
        }
      });
  }, [editModal, setMortgageDetails, templatePage]);

  const generateRows = useCallback(
    (rowsData, tabIndex) => {
      const components = [];

      rowsData.forEach((item, rowIndex) => {
        // Hide Same Lender Deals section, if user is coming from non lender template page.
        if (isLenderSpecific === false && item.identifier === "quickest" && item.products.length === 0) return;
        components.push(
          <DealRow
            key={rowIndex}
            title={item.title}
            description={item.sub_title}
            dealsData={item.products}
            selectedIndex={selectedIndex.rowIndex === rowIndex && selectedIndex}
            setSelectedDeal={(dealIndex) => handleDealClick(tabIndex, rowIndex, dealIndex)}
          />
        );
      });

      return components;
    },
    [deals, selectedIndex]
  );

  const generateTabs = useCallback(() => {
    if (!deals) return;

    const components = [];

    deals.forEach((item, index) => {
      components.push(
        <TabPanel key={index}>{generateRows(item?.product_category, index)}</TabPanel>
      );
    });

    return components;
  }, [deals, generateRows]);

  return (
    <div className="deals">
      <div className="deals__tabs-container">
        <Tabs>
          <TabList>
            <Tab>Popular</Tab>
            <Tab>Fixed</Tab>
            <Tab>Variable</Tab>
          </TabList>
          {dealsError ? (
            <div className="deals__error">
              <SecondaryTitle>Unable to fetch deals</SecondaryTitle>
            </div>
          ) : (
            <>{deals ? <>{generateTabs()}</> : <DotsLoader />}</>
          )}
        </Tabs>
      </div>
    </div>
  );
}

export default Deals;
