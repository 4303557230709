import React from 'react';
import { SecondaryDescription, SecondaryTitle } from '../Typography';
import BasicDealCard from './BasicDealCard';

function DealItem({ item, isSelected, setSelectedDeal }) {
  return (
    <BasicDealCard
      isSelected={isSelected}
      setSelectedDeal={setSelectedDeal}
      lenderName={item.lender_name}
      lenderLogo={item.lender_logo}
    >
      <div className="deals__item-info">
        <div className="deals__item-info-left">
          <p>{item.year_label}</p>
          <p>
            <span>{item.product_type}</span>
          </p>
          <span className="deals__item-info-dot" />
          <p>{item.initial_interest_rate}</p>
        </div>
        <div className="deals__item-info-right">
          <SecondaryDescription>Total Cost: {item.total_cost_over_initial_period_without_loan_fee}</SecondaryDescription>
        </div>
      </div>
      <div className="deals__item-price">
        <SecondaryTitle>{item.initial_monthly_payment}/m</SecondaryTitle>
      </div>
    </BasicDealCard>
  );
}

export default DealItem;
